import { graphql } from 'gatsby';

import { Page } from '../components/layout';

// import * as styles from './hours.module.scss';

const Hours = ({
	data: {
		markdownRemark: {
			fields: { slug },
			html,
		},
	},
}) => <Page slug={slug} body={html}></Page>;

export const hoursQuery = graphql`
	query hoursQuery($remarkId: String!) {
		markdownRemark(id: { eq: $remarkId }) {
			fields {
				slug
			}
			html
		}
	}
`;

export default Hours;
